<script setup>
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser, faSignOut, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import { user, signOut, signingOut, customerUrlSlug } from '../../store/user'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'petite-vue-i18n'

const { t } = useI18n()

const route = useRoute()
const router = useRouter()

const routeName = computed(() => route.name)

const onSignout = () => {
  signOut()
  router.push({ to: 'sign_in' })
}

const state = ref({
  burgerActive: false
})

const navElementRef = ref(null)
const clickHandler = e => {
  for (let node = e.target; node; node = node.parentNode) {
    if (node === navElementRef.value) {
      return
    }
  }

  // clicked outside nav element, deactiate burger menu
  state.value.burgerActive = false
}

const sectionStyle = computed(() => state.value.burgerActive ? 'min-height: 100dvh; min-height: 100vh;' : null)

const onAdministerUsers = () => {}

watch(
  () => state.value.burgerActive,
  active => {
    if (active) {
      window.addEventListener('click', clickHandler)
    } else {
      window.removeEventListener('click', clickHandler)
    }
  }
)
onBeforeUnmount(() => { window.removeEventListener('click', clickHandler) })
</script>

<template>
  <div
    class="app-nav app-has-yellow-background"
  >
    <section
      class="section mt-0 mb-0 pt-0 pb-0"
      :style="sectionStyle"
    >
      <div
        ref="navElementRef"
        class="container"
      >
        <nav
          class="navbar"
          role="navigation"
          aria-label="main navigation"
          style="background: unset;"
        >
          <div class="navbar-brand">
            <a
              class="navbar-item"
              href="/"
            >
              <img
                src="../../../assets/railpartner-brand.svg"
                height="28"
              >
            </a>

            <a
              role="button"
              class="navbar-burger has-text-dark"
              :class="{ 'is-active': state.burgerActive }"
              aria-label="menu"
              :aria-expanded="state.burgerActive"
              data-target="navbar-railpartner"
              @click="state.burgerActive = !state.burgerActive"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>

          <div
            id="navbar-railpartner"
            class="navbar-menu"
            :class="{ 'is-active': state.burgerActive }"
          >
            <div class="navbar-start has-text-centered">
              <router-link
                v-if="customerUrlSlug"
                :to="{ name: 'installations', params: { customerUrlSlug } }"
                class="navbar-item"
                :class="{ 'is-active': routeName === 'installations' }"
                @click="state.burgerActive = false"
              >
                {{ t('section.installations') }}
              </router-link>

              <router-link
                v-if="customerUrlSlug"
                :to="{ name: 'planning', params: { customerUrlSlug } }"
                class="navbar-item"
                :class="{ 'is-active': routeName === 'planning' }"
                @click="state.burgerActive = false"
              >
                {{ t('section.planning') }}
              </router-link>

              <div
                class="navbar-item is-hidden-desktop mt-2 pt-2 has-text-centered"
                style="border-top: 1px solid var(--bulma-grey-lighter)"
              >
                <span class="tag is-medium">
                  <span class="icon-text">
                    <span class="icon">
                      <FontAwesomeIcon :icon="faUser" />
                    </span>
                    <span>
                      {{ user.displayName }}
                    </span>
                  </span>
                </span>
              </div>

              <div class="navbar-item is-hidden-desktop has-text-centered">
                <router-link
                  v-if="user.isAdmin"
                  class="button is-link is-outlined mb-4"
                  :to="{ name: 'administer_users' }"
                  :disabled="signingOut ? 'disabled' : null"
                  @click="onAdministerUsers"
                >
                  <span class="icon is-small">
                    <FontAwesomeIcon :icon="faUsersCog" />
                  </span>
                  <span>
                    {{ t('app.administer_users') }}
                  </span>
                </router-link>

                <a
                  class="button is-link is-outlined"
                  :class="{ 'is-loading': signingOut }"
                  @click="onSignout"
                >
                  <span class="icon is-small">
                    <FontAwesomeIcon :icon="faSignOut" />
                  </span>
                  <span>
                    {{ t('app.sign_out') }}
                  </span>
                </a>
              </div>
            </div>

            <div class="navbar-end is-hidden-touch">
              <div class="navbar-item">
                <span class="tag is-white is-medium has-text-dark">
                  <span class="icon-text">
                    <span class="icon">
                      <FontAwesomeIcon :icon="faUser" />
                    </span>
                    <span>
                      {{ user.displayName }}
                    </span>
                  </span>
                </span>
              </div>

              <div class="navbar-item">
                <div clas="buttons">
                  <router-link
                    v-if="user.isAdmin"
                    class="button is-link is-outlined mr-2"
                    :to="{ name: 'administer_users' }"
                    :title="t('app.administer_users')"
                    :disabled="signingOut ? 'disabled' : null"
                    @click="onAdministerUsers"
                  >
                    <span class="icon is-small">
                      <FontAwesomeIcon :icon="faUsersCog" />
                    </span>
                  </router-link>

                  <a
                    class="button is-link is-outlined"
                    :class="{ 'is-loading': signingOut }"
                    :title="t('app.sign_out')"
                    @click="onSignout"
                  >
                    <span class="icon is-small">
                      <FontAwesomeIcon :icon="faSignOut" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
@use '../../../style/_colors.scss';

a.is-active {
  color: colors.$brandYellow;
}
</style>
