<script setup>
import CalendarComponent from '../common/calendar/CalendarComponent.vue'
import { onMounted, ref, computed, watch } from 'vue'
import { formatISO, parseISO } from 'date-fns'
import { setCustomerByUrlSlug, customerId } from '../../store/user'
import { getWorkOrders } from '../../api/work-orders'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { useI18n } from 'petite-vue-i18n'
import { addYearMonth } from '../../util/calendar'

const { t, locale } = useI18n()

const now = new Date()

const props = defineProps({
  customerUrlSlug: { type: String, required: true }
})

const state = ref({
  currentMonth: { year: now.getFullYear(), month: now.getMonth() + 1 },
  startMonth: addYearMonth({ year: now.getFullYear(), month: now.getMonth() + 1 }, -1),
  endMonth: addYearMonth({ year: now.getFullYear(), month: now.getMonth() + 1 }, 3),
  activeDate: formatISO(now, { representation: 'date' }),
  workOrders: [],
  loadingWorkOrders: false,
  showLoading: false
})

onMounted(() => {
  if (!customerId.value) {
    return
  }

  state.value.showLoading = false
  let showLoadingTimeout = window.setTimeout(() => {
    state.value.showLoading = true
    showLoadingTimeout = null
  }, 300)

  state.value.loadingWorkOrders = true
  getWorkOrders(customerId.value)
    .then(workOrders => {
      state.value.workOrders = workOrders
    })
    .finally(() => {
      state.value.loadingWorkOrders = false
      state.value.showLoading = false
      if (showLoadingTimeout) {
        window.clearTimeout(showLoadingTimeout)
      }
    })
})

const eventDates = computed(() => {
  return state.value.workOrders
    .map(workOrder => workOrder.date)
    .reduce((carry, date) => carry.includes(date) ? carry : carry.concat([date]), [])
})

const workOrdersOnActiveDate = computed(() => state.value.workOrders.filter(w => w.date === state.value.activeDate))

const formatDateLong = date => {
  const dateObject = parseISO(date)
  return new Intl.DateTimeFormat(locale.value, { dateStyle: 'full' }).format(dateObject)
}
watch(
  () => props.customerUrlSlug,
  urlSlug => setCustomerByUrlSlug(urlSlug),
  { immediate: true }
)
</script>

<template>
  <section class="section app-view-container">
    <div class="container">
      <div class="columns is-8">
        <div class="column">
          <div class="has-background-white-ter p-4">
            <CalendarComponent
              v-model:current-month="state.currentMonth"
              v-model:active-date="state.activeDate"
              :event-dates="eventDates"
              :start-month="state.startMonth"
              :end-month="state.endMonth"
            />
          </div>
        </div>
        <div class="column is-narrow is-hidden-mobile">
          <!-- spacer -->
          <div class="ml-2" />
        </div>
        <div
          class="column is-6-tablet is-7-desktop is-8-fullhd"
        >
          <progress
            v-if="state.showLoading"
            class="progress max=1"
          >
            {{ t('common.loading.label') }}
          </progress>

          <template v-if="!state.loadingWorkOrders">
            <!-- spacer -->
            <div class="is-hidden-tablet mt-2" />

            <h1 class="title is-2 is-size-4-mobile">
              {{ formatDateLong(state.activeDate) }}
            </h1>

            <p
              v-if="workOrdersOnActiveDate.length === 0"
              class="block"
            >
              {{ t('planning.message.no_work_orders') }}
            </p>

            <article
              v-for="workOrder of workOrdersOnActiveDate"
              :key="workOrder.id"
              class="media"
            >
              <div class="media-left">
                <span class="tag is-dark">
                  {{ workOrder.work_order_type.abbreviation }}
                </span>
              </div>
              <div class="media-content">
                <p class="block">
                  <strong>{{ workOrder.work_area.name }}</strong>
                </p>
                <table class="table is-striped">
                  <thead>
                    <tr>
                      <th>
                        <span class="is-hidden-mobile is-hidden-tablet-only">{{ t('planning.header.installation.record_number') }}</span>
                        <span class="is-hidden-desktop">{{ t('planning.header.installation.record_number_short') }}</span>
                      </th>
                      <th>
                        <span class="is-hidden-mobile is-hidden-tablet-only">{{ t('planning.header.installation.serial') }}</span>
                        <span class="is-hidden-desktop">{{ t('planning.header.installation.serial_short') }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="installation of workOrder.installations"
                      :key="installation.id"
                    >
                      <td>
                        {{ installation.record_number }}
                      </td>
                      <td>
                        {{ installation.serial }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="media-right">
                <span class="icon is-small">
                  <FontAwesomeIcon :icon="workOrder.night_shift ? faMoon : faSun" />
                </span>
              </div>
            </article>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.media-left > .tag {
  min-width: 6em;
}

.app-view-container {
  height: calc(100vh - 112px);
  height: calc(100dvh - 112px);
  overflow-y: scroll;
}
@media(max-width: 768px) {
  section.section {
    height: calc(100vh - 132px);
    height: calc(100dvh - 132px);
  }
}
</style>
