<script setup>
import { ref, computed, watch } from 'vue'
import { getUniqueElementId } from '../../../util/id'
import { useI18n } from 'petite-vue-i18n'
const { t } = useI18n()

const props = defineProps({
  workAreaOptions: { type: Array, default: () => [] },
  statusOptions: { type: Array, default: () => ['maintenance', 'install', 'remove_heads_tubes', 'remove_complete_inst', 'deactivate'] },
  selectedWorkAreaId: { type: Number, default: null },
  selectedStatuses: { type: Array, default: () => [] }
})

const emit = defineEmits(['update:selectedWorkAreaId', 'update:selectedStatuses'])

const state = ref({
  selectedWorkArea: null,
  selectedStatuses: [].concat(props.selectedStatuses)
})

const workAreaSelectOptions = computed(() => {
  const options = [{ value: '', label: '' }]
  props.workAreaOptions.forEach(workArea => {
    options.push({ value: String(workArea.id), label: workArea.name })
  })
  return options
})

const workAreaSelectElementId = getUniqueElementId()

watch(
  () => props.selectedWorkAreaId,
  workAreaId => {
    if (workAreaId === null) {
      state.value.selectedWorkArea = ''
    } else {
      const option = props.workAreaOptions.find(o => o.id === workAreaId)
      state.value.selectedWorkArea = option ? String(option.id) : ''
    }
  }
)

watch(
  () => props.selectedStatuses.join(','),
  () => {
    state.value.selectedStatuses = [].concat(props.selectedStatuses)
      .filter(s => props.statusOptions.includes(s))
  }
)

watch(
  () => state.value.selectedWorkArea,
  workAreaValue => {
    const workAreaId = workAreaValue === '' ? null : parseInt(workAreaValue)
    if (workAreaId !== props.selectedWorkAreaId) {
      emit('update:selectedWorkAreaId', workAreaId)
    }
  }
)

watch(
  () => state.value.selectedStatuses.join(','),
  () => {
    const changed = state.value.selectedStatuses.length !== props.selectedStatuses.length ||
            (
              !state.value.selectedStatuses.every(s => props.selectedStatuses.includes(s)) ||
                !props.selectedStatuses.every(s => state.value.selectedStatuses.includes(s))
            )
    if (changed) {
      emit('update:selectedStatuses', state.value.selectedStatuses)
    }
  }
)

</script>

<template>
  <div class="has-background-white-ter p-4">
    <div class="field block">
      <label
        class="label"
        :for="workAreaSelectElementId"
      >{{ t('installations.filter.work_area') }}</label>
      <div class="control is-expanded">
        <div class="select is-fullwidth">
          <select
            :id="workAreaSelectElementId"
            v-model="state.selectedWorkArea"
          >
            <option
              v-for="option of workAreaSelectOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="field block">
      <label class="label">{{ t('installations.filter.status') }}</label>
      <div class="control">
        <p
          v-for="status of props.statusOptions"
          :key="status"
        >
          <label class="checkbox">
            <input
              v-model="state.selectedStatuses"
              type="checkbox"
              :value="status"
            > {{
              t(`installations.status.${status}`) }}
          </label>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (prefers-color-scheme: dark) {
  .has-background-white-ter {
    background-color: #0C0D09 !important;
  }
}
</style>
